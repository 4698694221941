export const environment = {
	apiUrl: 'https://caveaugames-api.alesrepo.com',
	baseUrl: 'https://caveaugames.alesrepo.com',
	maxRetries: 3,
	stripeKey: 'pk_test_51PrxRR030TfqlOthN3cYrg3I7OBcFO4cchcrztlNY50YAs8TWRwlFP2fPodCc8vGJkkk7yDcebWs4KsgNjG9Krsu00xCoADD8L',
	keycloak: {
		url: 'https://keycloak.alesrepo.com',
		realm: 'alesrepo',
		clientId: 'caveaugames'
	}
};
