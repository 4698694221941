import { Injectable } from '@angular/core';
import { AuthType } from "../models/enumeration/auth-type";
import { KeycloakService } from "keycloak-angular";
import { KeycloakProfile } from "keycloak-js";
import { environment } from "../../environments/environment";
import { mergeMap, Observable, of, tap } from "rxjs";
import { fromPromise } from "rxjs/internal/observable/innerFrom";
import { User } from "../models/user";
import { HttpClient } from "@angular/common/http";
import { AuthResponse } from "../models/auth-response";
import { StorageService } from "./storage.service";
import { StorageKey } from "../models/enumeration/storage-key";
import { jwtDecode } from "jwt-decode";
import { Router } from "@angular/router";

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	constructor(
		private keycloakService: KeycloakService,
		private httpClient: HttpClient,
		private storageService: StorageService,
		private router: Router
	) {
	}

	public authenticateUserByIdp = (): void => {
		this.keycloakService.login({
			idpHint: 'google',
			redirectUri: `${ environment.baseUrl }/auth/post-auth?can-sync=true`
		});
	}

	public isUserLogged = (): boolean => {
		return this.keycloakService.isLoggedIn() || this.isAccessTokenValid();
	}

	public getUserDetails = (): Observable<KeycloakProfile> => {
		return this.keycloakService.isLoggedIn()
			? fromPromise(this.keycloakService.loadUserProfile(true))
			: this.httpClient.get<KeycloakProfile>(`${ environment.apiUrl }/users/me`);
	}

	public logout = (): Observable<void> => {
		if (this.keycloakService.isLoggedIn()) {
			return fromPromise(this.keycloakService.logout())
		}

		this.storageService.remove(StorageKey.AUTH);
		return of(void 0)
			.pipe(
				tap(_ => this.router.navigate(['/']),)
			);
	}

	public registerUser = (user: User): Observable<AuthResponse> => {
		return this.httpClient.post<void>(`${ environment.apiUrl }/auth/register`, user)
			.pipe(
				mergeMap(() => this.loginUserLocally(user.email, user.password))
			);
	}

	public loginUserLocally = (username: string, password: string): Observable<AuthResponse> => {
		return this.httpClient.post<AuthResponse>(`${ environment.apiUrl }/auth/login`, {
			username: username,
			password: password
		}).pipe(
			tap(authResponse => this.storageService.store(StorageKey.AUTH, authResponse))
		);
	}

	public refreshToken = (refreshToken: string): Observable<AuthResponse> => {
		return this.httpClient.post<AuthResponse>(`${ environment.apiUrl }/auth/refresh`, { refreshToken: refreshToken });
	}

	public isAccessTokenValid = (): boolean => {
		const tokenData = this.storageService.get<AuthResponse>(StorageKey.AUTH);

		if (!tokenData) {
			return false;
		}

		return this.isTokenValid(tokenData.accessToken);
	}

	public isRefreshTokenValid = (): boolean => {
		const tokenData = this.storageService.get<AuthResponse>(StorageKey.AUTH);

		if (!tokenData) {
			return false;
		}

		return this.isTokenValid(tokenData.refreshToken);
	}

	private isTokenValid = (token: string): boolean => {
		const decodedToken = jwtDecode(token);
		const now = Math.floor(Date.now() / 1000);

		return now < (decodedToken.exp ?? 0);
	}
}
